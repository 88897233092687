var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.$user.role === 'COACH' &&
      !_vm.$apollo.queries.groups.loading &&
      _vm.groups.length === 0
    )?_c('sb-call-out',[_c('template',{slot:"left"},[_vm._v(" ℹ️ ")]),_c('template',{slot:"right"},[_vm._v(" Er zijn nog geen groepen om een leerling aan toe te voegen. Vraag de schoolbeheerder om een groep aan te maken. ")])],2):_c('sb-user-form',_vm._g(_vm._b({ref:"form",attrs:{"validators":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function({ formData }){return [_c('sb-create-group-modal',{on:{"created":_vm.handleCreated},model:{value:(_vm.showCreateGroupModal),callback:function ($$v) {_vm.showCreateGroupModal=$$v},expression:"showCreateGroupModal"}}),_c('sb-divider',{attrs:{"id":"TypeReaderInput","title":"Zelfredzaamheid"}}),_c('form-item',{attrs:{"label":"Soort lezer","prop":"zelfredzaamheid"}},[_c('sb-info',[_c('p',[_vm._v(" Soort lezer. Redzaamheidslezer: Een lezer die eerst een focus heeft op nauwkeurig en vloeiend lezen in plaats van snel lezen. Tempolezer: Een lezer die een focus heeft op snel leren lezen. ")])]),_c('sb-zelfredzaamheid-select',{attrs:{"data-value-reference":formData.zelfredzaamheid},model:{value:(formData.zelfredzaamheid),callback:function ($$v) {_vm.$set(formData, "zelfredzaamheid", $$v)},expression:"formData.zelfredzaamheid"}})],1),_c('sb-divider',{attrs:{"title":"Schoolinformatie"}}),_vm._l((formData.organisations),function(organisation,i){return _c('form-item',{key:organisation.id,attrs:{"label":i === 0 ? 'School' : ''}},[_vm._v(" "+_vm._s(organisation.name)+" ")])}),_c('sb-course-group-select',{attrs:{"selected-group-id":formData.followingGroupsIds[0] ? formData.followingGroupsIds[0] : '',"disabled-fields":_vm.disabledFields},on:{"open":() => (_vm.showCreateGroupModal = true),"onchange":(courseGroupId) => {
            formData.followingGroupsIds = [courseGroupId];
          }}}),_c('form-item',{attrs:{"label":"Educatiegroep","prop":"classYear"}},[_c('i-select',{attrs:{"placeholder":"Selecteer een groep","data-value-reference":formData.classYear},model:{value:(formData.classYear),callback:function ($$v) {_vm.$set(formData, "classYear", $$v)},expression:"formData.classYear"}},_vm._l((_vm.EducationGroup),function(_type){return _c('i-option',{key:_type,attrs:{"value":_type}},[_vm._v(" "+_vm._s(_vm.getEducationGroupLabel(_type))+" ")])}),1)],1),_c('form-item',{attrs:{"label":"Leerlingnummer","prop":"studentNumber"}},[_c('i-input',{attrs:{"placeholder":"Leerlingnummer"},model:{value:(formData.studentNumber),callback:function ($$v) {_vm.$set(formData, "studentNumber", $$v)},expression:"formData.studentNumber"}})],1),_c('form-item',{attrs:{"label":"Notities"}},[_c('i-input',{staticClass:"notes",attrs:{"id":"OtherNotes","type":"textarea","placeholder":"Evt notities","autosize":{ minRows: 4, maxRows: 10 }},model:{value:(formData.notes),callback:function ($$v) {_vm.$set(formData, "notes", $$v)},expression:"formData.notes"}})],1),[(formData.followingGroups)?_c('sb-divider',{attrs:{"title":"Coach(es)"}}):_vm._e(),(formData.followingGroups)?_vm._l((_vm.toCoachArray(formData.followingGroups)),function(name,index){return _c('sb-list-item',{key:`coach-${index}`,attrs:{"label":name}})}):_vm._e()]]}}])},'sb-user-form',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }